@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --gradient: linear-gradient(to right, #8A2BE2, #DA70D6);
    --background: 270 100% 99%;
    --foreground: 270 60% 10%;
    --card: 270 30% 96%;
    --card-foreground: 270 60% 10%;
    --popover: 270 30% 96%;
    --popover-foreground: 270 60% 10%;
    --primary: 270 80% 50%;
    --primary-foreground: 270 10% 99%;
    --secondary: 280 40% 92%;
    --secondary-foreground: 270 60% 10%;
    --muted: 270 20% 90%;
    --muted-foreground: 270 30% 40%;
    --accent: 280 60% 85%;
    --accent-foreground: 270 60% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 270 30% 85%;
    --input: 270 30% 85%;
    --ring: 270 80% 50%;
    --radius: 0.5rem;
    --chart-1: 270 80% 50%;
    --chart-2: 290 70% 60%;
    --chart-3: 310 60% 70%;
    --chart-4: 330 50% 80%;
    --chart-5: 350 40% 90%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.bg-gradient {
  background: var(--gradient);
}

.purple-shadow {
  box-shadow: 0 4px 14px 0 rgba(138, 43, 226, 0.39);
}

.glass-effect {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}